import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/Layout';

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main style={pageStyles} className="flex justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-cnh-accent-cello text-center">We can't find the page you are looking for.</h1>
          <div className="rounded-lg bg-cnh-accent-flamingo w-32 mx-auto p-2 md:p-3 flex justify-center mt-10">
            <Link to="/" className="text-base text-white">Go home</Link>
          </div>
        </div>
      </main>
    </Layout>
    
  )
}

export default NotFoundPage
